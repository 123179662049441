/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    div: "div"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 665px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 66.26506024096386%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIDBAX/xAAWAQEBAQAAAAAAAAAAAAAAAAACAAH/2gAMAwEAAhADEAAAAeRfGpdBSz//xAAZEAEAAgMAAAAAAAAAAAAAAAABAAIQERP/2gAIAQEAAQUCHTaxyjn/xAAWEQEBAQAAAAAAAAAAAAAAAAAAERL/2gAIAQMBAT8B0r//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPwGH/8QAFhAAAwAAAAAAAAAAAAAAAAAAACBx/9oACAEBAAY/Air/AP/EABgQAQADAQAAAAAAAAAAAAAAAAEAEBEh/9oACAEBAAE/IchnA3VUqGf/2gAMAwEAAgADAAAAEPMv/8QAFhEBAQEAAAAAAAAAAAAAAAAAACER/9oACAEDAQE/EIjD/8QAFhEBAQEAAAAAAAAAAAAAAAAAABEh/9oACAECAQE/ENI//8QAGhABAAMBAQEAAAAAAAAAAAAAAQARMSFBsf/aAAgBAQABPxCxXG4IPKCnmvyWRekOZEqf/9k='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"sting&#39;s (Gordon Sumner) origin\"\n        title=\"sting&#39;s (Gordon Sumner) origin\"\n        src=\"/static/522a89af3429888d8d5cc606ca9c0954/0d8e0/sting.jpg\"\n        srcset=\"/static/522a89af3429888d8d5cc606ca9c0954/4d5fb/sting.jpg 166w,\n/static/522a89af3429888d8d5cc606ca9c0954/558f0/sting.jpg 333w,\n/static/522a89af3429888d8d5cc606ca9c0954/0d8e0/sting.jpg 665w,\n/static/522a89af3429888d8d5cc606ca9c0954/90334/sting.jpg 998w,\n/static/522a89af3429888d8d5cc606ca9c0954/6c738/sting.jpg 1200w\"\n        sizes=\"(max-width: 665px) 100vw, 665px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "Perhaps best known for his time with the Police, Gordon Matthew Thomas Sumner, better known as Sting , began his career near the Northumberland area of England. He would often play on the weekends with various jazz bands at local night clubs. After a particular performance at a local jazz club, the bandleader of the Phoenix Jazzmen (whom Gordon was playing with) commented that Gordon’s black and yellow striped sweater made him look like a bee. This observation sparked the nickname “Sting” to be forever associated with the musician."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
